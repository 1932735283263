body{
  background-color: #00C133;
}
.App {
  font-family: Avenir, sans-serif;
  color: #ffffff;
  text-align: center;
  background-color: #00C133;
  height: 200px;
  margin-top: 20vh;
}
.lottie{
  width: 150px;
}